<template>
  <div class="image-previewer-wrapper" v-show="showPreview" @mousewheel="handleMouseWheel">
    <div class="image-wrapper">
      <img
        ref="imgDom"
        draggable="true"
        class="image-preview"
        :style="{transform: `scale(${zoom}) rotate(${rotate}deg)`, marginTop: `${marginTop}px`, marginLeft: `${marginLeft}px`,}"
        :src="previewUrl"
        @mousedown="handleImgMouseDown"
        @mouseup="handleImgMouseUp"
        @mousemove="handleMouseMove"
      />
    </div>
    <i class="el-icon-close close-button" @click="close" />
    <i class="el-icon-back prev-button" @click="goPrev"></i>
    <i class="el-icon-right next-button" @click="goNext"></i>
    <div class="actions-bar">
      <i class="el-icon-zoom-out" @click="zoomOut"></i>
      <i class="el-icon-zoom-in" @click="zoomIn"></i>
      <i class="el-icon-refresh-left" @click="rotateLeft"></i>
      <i class="el-icon-refresh-right" @click="rotateRight"></i>
      <span class="image-counter">{{index+1}} / {{imgList.length}}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ImagePreviewer',
  data() {
    return {
      showPreview: false,
      url: '',
      index: 0,
      zoom: 1,
      rotate: 0,
      minZoom: 0.1,
      isMouseDown: false,
      mouseDownX: 0,
      mouseDownY: 0,
      lastML: 0,
      lastMT: 0,
      marginLeft: 0,
      marginTop: 0,
    }
  },
  computed: {
    imageStyle() {
      return {
        transform: `scale(${this.zoom});`
      }
    },
    previewUrl() {
      return this.imgList[this.index]
    }
  },
  mounted() {
    document.addEventListener("mouseup", () => {
      //console.log("document mouseup")
      this.isMouseDown = false
    })
  },
  methods: {
    handleImgMouseDown(e) {
      this.isMouseDown = true 
      //console.log("handleImgMouseDown e: ", e)
      this.mouseDownX = e.clientX
      this.mouseDownY = e.clientY
      this.lastML = this.marginLeft
      this.lastMT = this.marginTop
      // 不加此行代码拖动后不触发mouseup事件
      e.preventDefault()
    },
    handleImgMouseUp() {
      //console.log("handleImgMouseUp")
      this.isMouseDown = false 
    },
    handleMouseMove(e) {
      //console.log("handleMouseMove e: ", e)
      if(this.isMouseDown) {
        this.marginLeft = e.clientX - this.mouseDownX + this.lastML
        this.marginTop = e.clientY - this.mouseDownY + this.lastMT
      }
    },
    init() {
      this.showPreview = true
    },
    handleMouseWheel(event) {
      if (event.wheelDelta > 0) {
        this.zoomIn()
      } else {
        this.zoomOut()
      }
    },
    zoomIn() {
      this.zoom += 0.1
    },
    zoomOut() {
      this.zoom =
        this.zoom - 0.1 > this.minZoom ? this.zoom - 0.1 : this.minZoom
    },
    close() {
      this.showPreview = false
      setTimeout(() => {
        this.$destroy(true);
        this.$el && this.$el.parentNode.removeChild(this.$el);
      }, 500);
    },
    rotateLeft() {
      this.rotate -= 90
    },
    rotateRight() {
      this.rotate += 90
    },
    goNext() {
      this.index = (this.index + 1) % this.imgList.length
    },
    goPrev() {
      this.index =
        this.index - 1 >= 0 ? this.index - 1 : this.imgList.length - 1
    },
  }
}
</script>

<style scoped>
.image-previewer-wrapper {
  position: fixed;
  width: 100%;
  left: 0;
  top: 0;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  background: rgba(14, 12, 12, 0.8);
  z-index: 20000;
  /* cursor: zoom-out; */
  cursor: default;
}

.close-button {
  cursor: pointer;
  font-size: 28px;
  color: #000;
  position: fixed;
  top: 50px;
  right: 50px;
  background: rgba(255, 255, 255, 0.8);
  border-radius: 50%;
  padding: 6px;
}
.image-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.image-preview {
  transition: transform 0.1s ease 0s;
}
.actions-bar {
  display: flex;
  justify-content: space-around;
  align-items: center;
  position: fixed;
  bottom: 50px;
  left: 50%;
  margin-left: -100px;
  padding: 12px;
  border-radius: 6px;
  background: rgba(255, 255, 255, 0.8);
}
.actions-bar i {
  font-size: 24px;
  cursor: pointer;
  margin: 0 6px;
}

.prev-button,
.next-button {
  position: fixed;
  cursor: pointer;
  background: rgba(255, 255, 255, 0.8);
  border-radius: 50%;
  font-size: 24px;
  padding: 12px;
}
.prev-button {
  left: 0;
  top: 50%;
}
.next-button {
  right: 0;
  top: 50%;
}
.image-counter {
  background: rgba(20, 18, 20, 0.53);
  padding: 3px;
  border-radius: 3px;
  color: #fff;
}
</style>
